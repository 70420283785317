<template>
  <router-link
    :to="{ name: 'show-reservations', params: { id: payableId } }"
    class="block truncate text-base font-bold text-left"
  >
    {{ t('ttmt.payments.payable.reservation', { id: payableId }) }}
  </router-link>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

defineProps({
  payableId: {
    type: Number,
    required: true,
  },
})

const { t } = useI18n()
</script>
